@font-face {
  font-family: 'Altice';
  font-weight: 400;
  src: url('../../assets/fonts/Altice-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Altice';
  font-weight: 700;
  src: url('../../assets/fonts/Altice-Bold.otf') format('truetype');
}