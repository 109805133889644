@import "./fonts";

.small {
  font-size: smaller !important;
}

.pointer {
  cursor: pointer !important;
}

.bold {
  font-weight: 700 !important;
}
